
.vfya-bottm {
  margin-top: 126px;
}
.authentication-verify-form, .vya-btn {
  margin-top: 36px;
}
.otp-star9 {
  left: 62px;
}
  
.otp-star8 {
  right: 47px;
}

