@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@200;300;400;600&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap');

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  
  img {
    max-width: 100%;
  }

  body{
    background-color: #0F0B21 !important;
  }

  h2{
    font-family: 'Poppins', sans-serif;
  }

  label{
    font-family: 'Inter', sans-serif;
  }
  
  .nunito-fonts{
    font-family: 'Nunito Sans', sans-serif !important; 
  }

  .poppins-fstyle{
    font-family: 'Poppins', sans-serif !important;
  }

  .inter-fstyle{
    font-family: 'Inter', sans-serif !important; 
  }

  .word-brk{
    word-break: break-all;
  }
  
  .showVisibility{
    display: block;
  }

  .removeVisibility{
    display: none;    
  }

  .font-bold1{
    font-weight: 600 !important;
  }
  
  .font-bold2{
    font-weight: 400 !important;
  }
  
  .font-bold3{
    font-weight: 300 !important;
  }
  
  .font-bold4{
    font-weight: 500 !important;
  }

  .color-border{
    color: #4900FF;
    border-top: 4px solid;
  }

  /* .alertColor{
    color:red !important;
  } */

  .color1{
    color:#E4E4F0 !important;
  }

  .color2{
    color: #FFFFFF !important;
  }

  .color3{
    color: #A7A7CC !important;
  }

  .color4{
    color: #F2F2FA !important;
  }
  
  .color-yellow{
    color: #F2C94C !important;
  }

  .alertColor {
    margin-bottom: 0;
    color: #EB5757 !important;
    font-size: 14px;
    margin-top: 3px !important;
  }
  
  .opacity{
    opacity: 0.8;
  }
 
  .error-color, .red{
    color: #EB5757 !important;
  }

  .green{
    color: #07da63 !important;
  }

  .green-light{
    color: #6FCF97;
  }

  .blue{
    color: #56CCF2 !important;
  }

  .yellow{
    color: #F2C94C !important;
  }

  .orange{
    color: orange !important;
  }

  .yellow-d-color{
    color: #F2994A;
  }

  .blue1{
    color: rgba(47, 128, 237, 1) !important;
  }

  .hover-cls:hover{
    color: #a7a7cc85 !important;
  }

  .hover-blue:hover{
    color: #56ccf27a !important;
  }

  .btn-grey-hover:hover{
    background-color: #747474 !important;
  }
  
  .btn-blue-hover:hover{
    background-color: #4900ffbd !important;
    
  }

  .button-blue{
    background-color: #4900FF !important;
    border-color: #4900FF !important;
  }

  .linkhover:hover{
    color: #A7A7CC;
    opacity: 0.8;
  }
  
  /* .circle-cstm {
    align-items: center;
    justify-content: center;
    display: flex;
    height: 100%;
} */

  .lazy-loading-circle {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%); 
  }
 
  .bg-color{
    background-color: #212947 !important;
  }

  .bg-darkcolor{
    background-color: #0F0B21 !important;
  }

  .bg{
    border: none !important;
    background-color: transparent !important;
  }

  .wrap{
    white-space: nowrap;
  }

  .card-bg{
    background: rgba(15, 11, 33, 0.5);
    backdrop-filter: blur(52.5px);
    -webkit-backdrop-filter: blur(52.5px);
    border-radius: 26px;
  }

  .card-bg-dark{
    background: rgba(15, 11, 33, 0.8);
    backdrop-filter: blur(52.5px);
    -webkit-backdrop-filter: blur(52.5px);
    border-radius: 26px;
  }

  .bg-purple{
    background: #29297D !important;
    border-radius: 12px !important;
  }

  .bg-table{
    background: rgba(15, 11, 33, 0.8);
  }

  .red-border-element{
    border: 1px solid #EB5757 !important;
  }

  .active-border {
    border: 1px solid rgba(167, 167, 204, 0.3);
    border-radius: 16.1206px;
  }

  .cstmborder{
    border: 1.4472px solid #F2994A;
  }

  .border-radius{
    border-radius: 16.4684px !important;
  }

  .border-radius12{
    border-radius: 12px !important;
  }

  .border-radius10{
    border-radius: 10px !important;
  }

  .border-radius-n{
    border-radius: unset !important;
  }

  .border-radius26{
    border-radius: 26px !important;
  }

  .border-radius23{
    border-radius: 23px !important;
  }

  .border-radius24{
    border-radius: 24px;
  }

  .borderless-input{
    border-color: transparent !important;
  }

  .place-item{
    place-items: center;
  }

  .maxwidth{
    max-width: 486px !important;
  }

  .maxwidth1290{
    max-width: 1290px !important;
  }

  .width48{
    width: 48px;
  }
  
  .cstm-font{
    font-size: 18px !important;
  }

  .cstm-font1{
    font-size: 14px !important;
  }

  .cstm-font2{
    font-size: 16px !important;
  }

  .plchldr-16::placeholder{
    font-size: 16px !important;
  }

  .cstm-font3{
    font-size: 13px !important;
  }

  .cstm-font5{
    font-size: 12px;
  }

  .cstm-font4 {
    font-size: 30px !important;
  }

  .cstm-font24 {
    font-size: 20px !important;
  }

  .error-msg{
    font-size: 14px;
  }

  .height52{
    height: 52px !important;
  }

  .height520{
    height: 520px;
  }

  .cstm-btn{
   background-color: transparent;
   border: none;
   box-shadow: none;
  }
  
  .cstm-zindex{
    z-index: -1;
  }
 /* table css */
  thead.spt-trd-head {
    border: 0.5px solid rgba(167, 167, 204, 0.6);
  }

  .spot-trade-topsec {
    border-bottom: 1px solid #0F0B21;
    position: relative;
  }
 
  .spot-trade-border{
    position: absolute;
    width: 116px;
    height: 4px;
    background: #FFFFFF;
    border-radius: 17px;
    left: 8px;
  }
  
  .spot-trade-table {
    background: rgba(15, 11, 33, 0.8);
    backdrop-filter: blur(52.5px);
    -webkit-backdrop-filter: blur(52.5px);
    border-radius: 26px;
    /* height: 430px; */
    /* max-width: 1290px !important; */
  }

  .spot-trade-table table{
    border-style: hidden;
    border-color: transparent !important;
    border-collapse: separate;
    border-spacing: 0 8px;
    /* max-width: 1290px !important; */
    border-top: 0.5px solid rgba(167, 167, 204, 0.6) !important;
    /* background: rgba(15, 11, 33, 0.8);
    backdrop-filter: blur(52.5px); */
  }

  .st-coin-name {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 11px;

  }

  .tbl-wdt{
    width: 300px;
  }

  .st-coin-name img {
    height: 33px;
    width: 33px;
  }

  .drpdwn-btn {
    background-color: transparent !important;
    border: none !important;
    padding: 0 !important;
  }

  .drpdwn-btn::after {
    display: none !important;
  }

  .spot-trade-tbody tr{
    border-style: hidden;
    background: rgba(255, 255, 255, 0.02);
  }
  
  .spot-trade-table table thead tr th, .spot-trade-table table thead tr td, .spot-trade-table table tbody tr th, .spot-trade-table table tbody tr td {
    vertical-align: middle;
  }

  .spot-tbl-btn{
    padding: 8px 32px !important; 
  }
  
  .spot-tbl-dropdown-menu {
    border-radius: 12px;
    width: 353px;
    border-radius: 12px !important;
  }
  
  .receive-icon{
    transform: rotate(180deg);
  }

  .spot-tbl-dropdown-menu hr{
    border-color: rgba(167, 167, 204, 0.3);
  }

  .spot-tbl-dropdown-menu a:hover {
    background-color: transparent;
  }
  
  button.sort-btn {
    line-height: 0;
    background-color: transparent;
    border: none;
    padding: 0 3px;
    margin: 0;
  }

  .sort-btn{
    display: flex;
    flex-direction: column;
  }
  
  input.tbl-seacrh-input.form-control {
    background: transparent;
    border: none;
    max-width: 42px;
    padding: 0;
    box-shadow: none;
  }
  
  .tbl-seacrh-input::placeholder{
    color: #F2F2FA !important;
    font-weight: 400 !important;
    font-size: 18px !important;
  }

  .search-icon{
    background: none !important; 
    border: none !important;
  }

  .srch-input{
    max-width: 111px !important;
  }

  .srch-input::placeholder{
    font-size: 16px !important;
  }

  .search-bar{
    /* border: 1px solid rgba(73, 0, 255, 0.4) !important; */
    border: 1px solid #4900FF;
    box-shadow: 0px -3px 20px rgba(73, 0, 255, 0.34);
  }

  .close-ico{
  right: 5px;
  }
  /* table css ends*/

  /* splash screen css start*/
  
  .splash-logo {
    position: relative;
    z-index: 10;
  }
  
  .logout-button{
    padding: 20px;
    display: flex;
    top: 0;
    position: absolute;
    right: 0;
    justify-content: flex-end;
  }

  .splash-screen{
    position: relative;
    height: 100vh;
    display: flex;
    align-items: center;
  }
    
  .common-gradient-bg{
    background: linear-gradient(90deg, #091E3A 0%, #2F80ED 50%, #2D9EE0 100%);
   -webkit-opacity: 0.6 !important; /* Chrome & Safari */
    opacity: 0.2;
    filter: blur(331.188px);
    position: absolute;
    left: 5.42%;
    right: 22.64%;
    top: -5.64%;
    bottom: 10.69%;
  }

/* splash screen css ends*/

/* check box css start */

  .custom-form-check {
    display: flex;
  }
  
  .custom-check-label{
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-size: 16.0233px;
    line-height: 22px;
    margin-left: 10px;
  }
  
  .custom-check{
    border: 3px solid #FFFFFF !important;
    border-radius: 3px !important;
    box-shadow:none !important;
    width: 17px !important;
    height: 17px !important;
    background-color: transparent !important; 
  }

  .custom-check:checked[type=checkbox] {
    background-image: url(./assets/images/check.svg) !important;
    border: none !important;
    background-size: 105%;
  }

/* check box css ends */

/* authentication card css start*/

  .back-icon{
    color: #0F0B21;
    font-size: 18px;
  }
  
  .back-icon-cover{
    justify-content: center;
    align-items: center;
    display: flex;
    background: #E4E4F0;
    border: 1px solid #D8DADC;
    border-radius: 10px;
    width: 39px;
    align-items: center;
    height: 39px;
  }

  .logo-img{
    width: 134px;
    height: 134px;
    position: relative;
    z-index: 1;
  }

/* .card-body.authentication-card-body {
  padding: 1.5rem 9rem;
} */

  .authentication-card{
    background: rgba(255, 255, 255, 0.06) !important;
    backdrop-filter: blur(58.5px);
    border-radius: 12px !important;
    width: 550px;
    max-width: 550px;
    height: 100% !important;
  }

  .recaptcha{
    margin-bottom: 35px;
  }

/* .border-gr {
  border-style: dotted !important;
  border: 1px solid;
  border-image-source: linear-gradient(180deg, rgba(255, 255, 255, 0.54) 0%, rgba(255, 255, 255, 0) 100%) !important;
  border-image-slice: 1 !important;
} */

  .border-gr {
    position: relative !important;
  }
  
  .border-gr::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 12px;
    padding: 1px 0.8px;
    background:linear-gradient(180deg, rgba(255, 255, 255, 0.54) 0%, rgba(255, 255, 255, 0) 100%); 
    -webkit-mask: 
       linear-gradient(#fff 0 0) content-box, 
       linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
            mask-composite: exclude; 
  }

  .authentication-card-sub-heading{
    font-style: normal;
    font-size: 19px;
    line-height: 125%;
    text-align: center;
  }
  
  .authentication-card-sub-heading2{
    font-style: normal;
    line-height: 125%;
    text-align: center;
  }
  
  .authentication-card-label{
    font-size: 13.8357px;
    line-height: 125%;
    text-transform: uppercase;
  }

  .star9{
    position: absolute;
    top: 57px;
    left: 107px;
  }
   
  .star {
    top: 0;
  }
  
  .star8{
    top: 27px;
    right: 89px;
    position: absolute;
  }

  .authentication-card-title{
    font-size: 30px;
    margin-top: 14px;
    margin-bottom: 29px;
    line-height: 130%;
    text-align: center;
    letter-spacing: -0.01em;
    color: #F2F2FA;
  }
  
  .authentication-card-input:focus, .authentication-card-input-code:focus{
    border-color: #E4E4F0 !important;
  }
  
  .authentication-card-input{
    background-color: #212947 !important;
    border: 1.15297px solid rgba(167, 167, 204, 0.3) !important;
    border-radius: 11.5297px !important;
    box-shadow: none !important;
    color: #E4E4F0 !important;
    font-weight: 400 !important;
    font-size: 18.4476px !important;
    padding: 11.1416px 18.4476px !important;
    height:45px;
  }

  .authentication-card-input-code{
    background-color: #212947 !important;
    border: 1.15297px solid rgba(167, 167, 204, 0.3) !important;
    border-radius: 11.5297px !important;
    box-shadow: none !important;
    color: #E4E4F0 !important;
    font-weight: 400 !important;
    font-size: 18.4476px !important;
    padding: 11.1416px 18.4476px 11.1416px 133.4476px !important;
    height:45px;
  }
  
  .authentication-card-input-code::placeholder, .authentication-card-input::placeholder{
    color: #A7A7CC !important;
    opacity: 0.8;
  }

  .authentication-form {
    flex-direction: column;
    margin-top: 8rem;
    display: flex;
    max-width: 360px;
    margin:auto;
  }

/* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  
  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
    appearance:textfield;
  }
  
  .authentication-button{
    background-color: #4900FF !important;
    box-shadow: 0px 6px 30px rgba(73, 0, 255, 0.34);
    border-radius: 10px !important;
    border: none !important;
    font-family: 'Inter', sans-serif !important;
    font-style: normal;
    font-weight: 600 !important;
    font-size: 16px !important;
    animation-timing-function: ease-out;
    animation-duration: 500ms;
    width: 353px;
    height: 47px;
  }

  .authentication-button:hover{
    background-color: #4900ffbd !important;
  }
  
  .eye-color{
    position: absolute;
    width: 23.06px;
    right: 41px;
    top: 42px;
    height: 23.06px;
  }
  
  .resend-btn{
    background: transparent !important;
    border: none !important;
  }
  
  .verify-section, .verify-btn{
    margin-top: 56px;
  }

  .acc-restricted-p{
    font-style: normal;
    font-size: 15px;
    line-height: 130%;
    letter-spacing: -0.01em;
  }
  
  .acc-res-heading{
    max-width: 427px;
    display: flex;
    margin: auto;
  }
  
  .reset-cancel-btn, .reset-con-btn{
    width: 100%;
    height: 47px;
    border: 1px solid #747474;
    border-radius: 10px !important;
    color: #fff !important;
    font-size: 18px !important;
  }
  
  .reset-con-btn{
    background: #4900FF !important;
    box-shadow: 0px 6px 30px rgba(73, 0, 255, 0.34);
    border-radius: 10px !important;
    border: none !important;
    font-size: 18px !important;
    display: flex !important;
    align-items: center;
    justify-content: center;
  }
  
  .reset-body{
    padding: 1rem 2.5rem !important;
  }  

  .info-box{
    gap: 13px;
    padding: 6px 0;
    display: flex;
    align-items: center;
  }
  
  input.pincode-input-text {
    border-radius: 13.1303px;
    width: 48px !important;
    height: 48px !important;
    color: #E4E4F0;
    background: #212947 !important;
  }

  .pincode-input-container{
    gap: 10px;
    display: flex;
    justify-content: center;
  }
  
  .update-text{
    max-width: 341px;
    display: flex;
    margin: 0 auto;
  }
  
  .responsive-img{
     width: 135px;
  }

  /* .pin-input{
    -webkit-text-security: disc;
    text-security: disc;
  } */

/* authentication card css ends*/

/* country code css starts */

  .country-code .MuiInputBase-formControl .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input{
    padding: 16px 10px 12.5px 10px;
  }

  .country-code .MuiInputBase-formControl .MuiSelect-select {
    padding-right: 0 !important;
  }

  .country-code {
    background: #0F0B21;
    border-radius: 11.5297px !important;
    position: absolute !important;
    z-index: 9;
    height: 43.04px;
    background-image: url(./assets/images/arrow.svg);
    background-repeat: no-repeat;
    background-position: 96px center;
    display: flex !important;
    justify-content: center;
  }
  
  .country-code .MuiInputBase-formControl{
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-size: 16.1416px;
    color: #F2F2FA;
    overflow: hidden;
    padding-top: 58px;
    line-height: 60px;
  
  }
  
  .country-code fieldset{
    border-color: transparent !important;
  }
  
   .css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper{
    max-height: calc(100% - 470px) !important;
  }
  
  .country-code .MuiInputBase-formControl .MuiSelect-icon{
    display: none;
  }
  
  .cstm-country-code{
    position:relative !important;
    background-position: 97% center;
    background-image: none;
    display: flex !important;
    flex-direction: row !important;
    padding: 0.8rem !important;
    align-items: center;
  }

  .cstm-country-code div{
    width: 100% !important;
  }

  .cstm-country-code div div{
     display: flex;
     align-items: center;
     gap: 4px;
  }

  .cstm-country-code .MuiInputBase-formControl{
    overflow: unset;
    padding: 0;
    line-height: unset;
  }

  .cstm-country-code .MuiInputBase-formControl .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
    padding: 0;
  }

  /* .cstm-country-code.css-1ka5eyc-MuiPaper-root-MuiMenu-paper-MuiPopover-paper{
    max-height: calc(100% - 500px) !important;
    min-width: 35% !important;
    top: 442px !important;
    left: auto !important;
    right: 88px !important;
  } */


/* country code css ends */

.euro-icon{
  width:9%;
}

/*responsive css starts*/
@media (min-width: 1024.98px) { 
   .section{
       overflow-y: hidden;
   }
  
}

@media (max-width: 1024.98px) { 

  .custom-tooltip1 .tooltip-inner{
    
      margin-top: 5px !important;
    }
    .custom-tooltip1 .tooltip-inner::before{
      top: -6% !important;    
    }
}
@media (max-width: 768.98px) { 
  .splash-logo {
    height: 230px;
    position: relative;
    z-index: 10;
    width: 230px;
  } 
  .recaptcha {
    margin-bottom: 22px;
  }
  
  .logo-img {
    width: 102.33px;
    height: 102.33px;
  }

  .star8 {
    right: 24px !important;
  }
   
  .star9 {
    left: 57px !important;
  }

  .authentication-card {
    width: 100%;
    height: 100% !important;
  }

  .back-icon-cover {
    left: 10px;
    width: 35px;
    height: 35px;
  }

  .star9 {
    width: 19px;
    top: 45px;
  }

  .star8 {
    width: 39px;
    top: 14px;
  }

  .verify-btn {
    margin-bottom: 3rem;
  }

  .evc-btn {
    margin-bottom: 2rem;
    /* margin-top: 8rem !important; */
  }

  .reset-body {
    padding: 1rem !important;
  }

  .reset-btns {
    margin-bottom: 1rem;
    margin-top: 2rem;
  }

  .custom-offcanvas.offcanvas.offcanvas-end.show {
    width: 600px !important;
  }

  .toast-container.start-50 {
    left: 50% !important;
  }

  .spot-trade-nav .navbar-nav{
    display: flex;
    flex-direction: column-reverse;
    padding: 10px;
    align-items: center;
  }

  /* .spot-trade-table{
    height: 100%;
  } */
  
  .custom-toast{
    bottom: 10% !important;
    width: 89% !important;
    display: flex !important;
    justify-content: center !important;
    margin: 0 auto;
  }
  .custom-tooltip2 .tooltip-inner {
    top: 50%;
    left: 50%;
    transform: translate(-160%, -10%);
    width: 300px !important;
    max-width: 300px !important;
  }
}

@media (max-width: 575.98px) { 
  img.star9.evc-star9 {
    left: 19px !important;
  } 
  .evc-star8 {
    right: 15px !important;
  }
  .authentication-button{
    width: 100%;
  }

  .authentication-card-title {
    font-size: 25px;
  }
  
  .authentication-card-label {
    font-size: 11.8357px;
  }

  .authentication-card-input{
    font-size: 15.4476px !important;
    padding: 8.1416px 18.4476px !important;
    height: 47.28px;
  }

  .authentication-card-input-code {
    height: 47.28px;
    font-size: 15.4476px !important;
    padding: 8.1416px 18.4476px 8.1416px 116.4476px !important;
  }

  .country-code .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
    padding: 7.5px 6px;
  }
  
  .country-code {
    min-width: 107px !important;
    background-position: 82px center;
    height: 43.04px;
  }

  .country-code .MuiInputBase-formControl {
    font-size: 14.1416px;
  }

  .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root {
    font-size: 14.1416px !important;
  }

  .responsive-col-img {
    width: 20px;
  }

  .eye-color {
    width: 19.06px;
    right: 36px;
    top: 44px;
    height: 19.06px;
  }

  .custom-check-label {
    font-size: 15.0233px;
    line-height: 22px;
    margin-left: 13px;
  }

  input.pincode-input-text {
    width: 45px !important;
    height: 45px !important;
    border-radius: 10.1303px;
  }

  .otp-label {
    margin-left: 2rem;
  }

  .authentication-card-sub-heading {
    font-size: 16px;
  }

  .star9 {
    width: 16px;
  }

  .star8 {
    width: 28px;
  }

  .vfya-bottm {
    margin-bottom: 2rem;
    margin-top: 78px;
  }

  .responsive-img {
    width: 95px;
  }

  .verify-btn {
    margin-top: 5rem;
  }
  
  .resetinfo-heading{
    font-size: 18px !important;
  }

  .acc-restricted-p {
    font-size: 13px;
  }

  .info-box h6 {
    font-size: 14px;
  }

  .authentication-bottom-sec {
    margin-top: 5rem !important;
  }

  .resend-btn {
    font-size: 13px !important;
    margin-right: 2rem;
  }  

  .counter{
    margin-right: 2rem;
  } 

  .authentication-enter-verify-form {
    margin-top: 2rem !important;
  }

  .custom-offcanvas.offcanvas.offcanvas-end.show {
    width: 403px !important;
  }
  /* .custom-toast {
    bottom: 268px !important;
  } */
  .custom-tooltip .tooltip-inner{
    right: unset !important;
    width: 300px !important;
    max-width: 300px !important;
  }
}

@media (max-width: 414.98px) { 
  .star9 {
    display: none;
  }

  .star8 {
    display: none;
  }

  .back-icon-cover {
    left: 6px;
    width: 27px;
    height: 31px;
  }

  .otp-label {
    margin-left: 9px;
  }

  .reset-btns {
    margin-top: 3rem;
  }

  .counter{
    margin-right: 1rem;
  } 

  .resend-btn {
    margin-right: 9px;
  }
  .custom-offcanvas.offcanvas.offcanvas-end.show {
    width: 350px !important;
}
.custom-tooltip2 .tooltip-inner {
  top: 50%;
  left: 50%;
  transform: translate(-160%, -50%);
  width: 300px !important;
  max-width: 300px !important;
}

.custom-tooltip3 .tooltip-inner::before, .custom-tooltip4 .tooltip-inner::before{
  top: -8% !important;

}
.custom-tooltip1 .tooltip-inner::before{
  transform: translate(-50%, -50%) !important;
  top: -7.5px !important;
  left: 140px ;
}
.custom-tooltip1 .tooltip-inner{
  margin-top: 5px !important;
}
.custom-tooltip2 .tooltip-inner::before{
display: none !important;
}
.custom-tooltip2 .tooltip-inner{

  margin-left:18px !important ;
margin-top: 5rem !important; 
}
 }


 @media (max-width: 390.98px) { 
  .custom-offcanvas.offcanvas.offcanvas-end.show {
    width: 330px !important;
}
}

@media (max-width: 375.98px) { 
  .custom-offcanvas.offcanvas.offcanvas-end.show {
    width: 310px !important;
}
  .authentication-card-title {
    font-size: 23px;
  }

  input.pincode-input-text {
    width: 40px !important;
    height: 40px !important;
    border-radius: 10.1303px;
  }

  .pincode-input-container {
    gap: 5px;
  }

  .resend-btn {
    margin-right: 15px;
  }
  .custom-tooltip2 .tooltip-inner {
    top: 50%;
    left: 50%;
    transform: translate(-150%, -50%);
  }
  .custom-tooltip2 .tooltip-inner {
    top: 50%;
    left: 50%;
    transform: translate(-155%, -50%);
    width: 300px !important;
    max-width: 300px !important;
  }
}

@media (max-width: 360.98px) { 
  .eye-color {
    right: 21px;
  }
  .custom-check {
    width: 22px !important;
    height: 22px !important;
}
  .authentication-card-input {
    padding: 8.1416px 10.4476px !important;
  }

  .authentication-card-title {
    font-size: 18px;
  }

  .authentication-card-sub-heading {
    font-size: 13px;
  }

  .resend-btn {
    margin-right: 9px;
  }

  /* .custom-offcanvas.offcanvas.offcanvas-end.show {
    width: 310px !important;
  }   */
 }

@media (max-width: 320.98px) { 
  .custom-offcanvas.offcanvas.offcanvas-end.show {
    width: 100% !important;
  }
  .custom-tooltip2 .tooltip-inner {
    top: 50%;
    left: 50%;
    transform: translate(-148%, -50%);
    width: 300px !important;
    max-width: 300px !important;
  }
}

/*responsive css ends here*/

/* toast styling signup */

  .custom-toast{
    bottom: 7rem;
    width: 100%;
    min-height: 30px !important;
    left: 0;
    right: 0;
    border: 2px solid red;
    border-radius: 10px !important;
  }
  
  .success-toast {
    border: 2px solid #27AE60 !important;
    border-radius: 10px !important;
    position: absolute;
    z-index: 9;
    bottom: 1rem;
    right: 0;
    left: 0;
    margin: auto;
    background-color: #FFFFFF !important;
  }
  
  .success-toast .toast-body {
    font-weight: 500;
    display: flex;
    /* align-items: center; */
    gap: 8px;
    font-size: 16px;
  }
  
  .Toastify__close-button{
    opacity: 1 !important;
    padding-top: 5px !important;
  }

  .Toastify__close-button--light{
    opacity: 1 !important;
    padding-top: 10px;
  }
  
  .Toastify__toast-theme--light{
    color: black !important;
  }
  
  .custom-toast .Toastify__progress-bar--error{
    background: transparent !important;
  }
  
  .copy-cursor{
    cursor: pointer;
  }
  
  .receive-address-container{
    background: #212947;
    border-radius: 20.5882px;
    display: flex;
    justify-content: space-between;
    cursor:pointer;
  }

  .addbankform-toast{
    top: 50%;
    left: 50%;
    bottom: unset;
    transform: translate(-50%, -50%);
  }

/* pagination css */

  .section-pagination .page-item a{
    background: transparent !important;
    border-radius: 8px !important;
    color: #fff;
    width: 36px;
    height: 38px;
    display: flex;
    align-items: center;
    font-family: 'Poppins', sans-serif !important;
    justify-content: center;
    border-color: #A7A7CC;
    font-size: 14px;
    box-shadow: none !important;
  }
  
  .active>.page-link, .section-pagination.page-item.page-link.active {
    background-color: rgba(73, 0, 255, 0.4) !important;
    border-color: rgba(73, 0, 255, 0.4) !important;
    border-radius: 8px !important;
    font-size: 14px;
    box-shadow: none !important;
    width: 36px;
    height: 38px;
    display: flex;
    align-items: center;
    font-family: 'Poppins', sans-serif !important;
    justify-content: center;
  }
  
  .disabled>.page-link, .section-pagination.page-item.page-link.disabled {
    background-color: rgba(73, 0, 255, 0.4) !important;
    border-color: rgba(73, 0, 255, 0.4) !important;
    border-radius: 8px !important;
    font-size: 14px;
    box-shadow: none !important;
    width: 36px;
    height: 38px;
    display: flex;
    align-items: center;
    font-family: 'Poppins', sans-serif !important;
    justify-content: center;
  }

  .dot1{
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: linear-gradient(128.46deg, #8143BF 27.87%, #8282FA 66.62%);
  }

  .dot2{
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: linear-gradient(338.35deg, #4358BF 17.33%, #7087FA 65.14%);
  }

  .dot3{
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: linear-gradient(232.16deg, #3083BF 20.44%, #64AFFA 80.76%, #70C1FA 80.76%);
  }

  .dot4{
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: linear-gradient(452.13deg, #5757ff 20.44%, #61b0ff 80.76%, #70C1FA 30.76%);
  }

  .dot5{
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: linear-gradient(90deg, #1D976C 0%, #93F9B9 100%);
  }
 
  .cstm-doc-restcd-box{
    max-width: 271px !important;
    /* height: 153px !important; */
  }

  .export-button{
    margin-right: 1rem;
  }

  .export-icon{
    height: 1.5rem;
    width: 1.5rem;
  }

  .noEntryMargin{
    margin-bottom: 30rem;
  }

  .arrow{
    color: white;
  }

  .arrow-icon{
    height:2rem;
    width:2rem;
  }

  .balanceElement{
    position: relative;
    right: 4rem;
  }

  .send-receive-coin-logo{
    width: 24px;
    height: 24px;
  }
  