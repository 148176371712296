
.bottom-margin {
    margin-top: 2.5rem;
}

.authentication-bottom-sec {
    margin-top: 11rem;
}

.authentication-enter-verify-form {
    margin-top: 5rem;
}

.evc-bottom-sec {
    margin-top: 40px;
}

.evc-star9 {
    left: 53px;
  }
  
.evc-star8 {
    right: 32px;
}

.evc-btn, .reset-btns {
    margin-top: 40px;
}

.authentication-pin-form {
    margin-top: 94px;
}


